// Example: Adding lightbox effect using react-image-lightbox
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./style.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";
function Gallery() {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  function importAll(r) {
    return r.keys().map(r);
  }

  const images = importAll(require.context("assets/images/Gallery", false, /\.(png|jpe?g|svg)$/));
  const openLightbox = (index) => {
    setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  // ... Your existing code ...

  return (
    <>
      {/* Your gallery grid */}
      {/* {images.map((img, index) => (
        <div  key={index} onClick={() => openLightbox(index)}>
          <img width={400} src={img.default} alt={`Image ${index}`} />
        </div>
      ))} */}
      <DefaultNavbar
        routes={routes}
        light
        // relative
      />
      <br></br>
      <br></br>
      <br></br>
      <Card  sx={{
          p: 6,
          mx: { xs: 2, lg: 3 },
          mt: -1,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}>
      <Container>
        <div className="image-row">
          {images.map((image, index) => (
            <div className="gallery-item" key={index} onClick={() => openLightbox(index)}>
              <img width={400} src={image.default} alt={`Image ${index}`} />
            </div>
          ))}
        </div>
        {lightboxOpen && (
          <Lightbox
            mainSrc={images[selectedImageIndex].default}
            nextSrc={images[(selectedImageIndex + 1) % images.length].default}
            prevSrc={images[(selectedImageIndex + images.length - 1) % images.length].default}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setSelectedImageIndex((selectedImageIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setSelectedImageIndex((selectedImageIndex + 1) % images.length)
            }
          />
        )}
      </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Gallery;
