// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";

// Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Inputs from "layouts/sections/input-areas/inputs";
import Forms from "layouts/sections/input-areas/forms";
import Alerts from "layouts/sections/attention-catchers/alerts";
import Modals from "layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "layouts/sections/elements/avatars";
import Badges from "layouts/sections/elements/badges";
import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
import Buttons from "layouts/sections/elements/buttons";
import Dropdowns from "layouts/sections/elements/dropdowns";
import ProgressBars from "layouts/sections/elements/progress-bars";
import Toggles from "layouts/sections/elements/toggles";
import Typography from "layouts/sections/elements/typography";
import InventoryIcon from '@mui/icons-material/Inventory';
import IceBlockMakingMachine from "pages/LandingPages/IceBlockMakingMachine";
import DirectRefrigerationBlockIceMachine from "pages/LandingPages/DirectRefrigerationBlockIceMachine";
import Presentation from "pages/Presentation";
import PresentationPage from "layouts/pages/presentation";
import AboutUsPage from "layouts/pages/landing-pages/about-us";
import Gallery from "pages/LandingPages/Gallery";
import IceCube from "pages/LandingPages/IceCube";
import IceCrusher from "pages/LandingPages/IceCrusher";
import IceCutting from "pages/LandingPages/IceCutting";
import ColdRoom from "pages/LandingPages/ColdRoom";
import FlakeIceMachine from "pages/LandingPages/FlakeIceMachine";
import VideoGallery from "pages/LandingPages/VideoGallery/VideoGallery";
const routes = [
  // {
  //   name: "github",
  //   icon: <GitHubIcon />,
  //   href: "/presentation",
  // },
  {
    name: "Home",
    route: "/",
    component: <PresentationPage />,
  },
  {
    name: "About Us",
    route: "/about-us",
    component: <AboutUs />,
  },
  {
    name: "Products",
    icon: <InventoryIcon/>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name:"Ice Products",
        collapse: [
          {
            name: "Ice Block Making Machine",
            route: "/products/ice-block-making-machine",
            component: <IceBlockMakingMachine />,
          },
          {
            name: "Direct Refrigeration Block Ice Machine",
            route: "/products/direct-refrigeration-block-ice-machine",
            // component: <ContactUs />,
            component: <DirectRefrigerationBlockIceMachine />
          },
          {
            name: "Ice Cube Machine",
            route: "/products/ice-cube-machine",
            component: <IceCube />,
          },
          {
            name: "Ice Crusher Machine",
            route: "/products/ice-crusher-machine",
            component: <IceCrusher />,
          },
          {
            name: "Ice Cutting Machine",
            route: "/products/ice-cutting-machine",
            component: <IceCutting />,
          },
          {
            name: "Cold Room",
            route: "/products/cold-room",
            component: <ColdRoom />,
          },
          {
            name: "Flake Ice Machine",
            route: "/products/Flake-ice-machine",
            component: <FlakeIceMachine />,
          },
        ],
      },
    ],
  },
  {
    name: "Gallery",
    icon: <InventoryIcon/>,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name:"Explore Gallery",
        collapse: [
          {
            name: "Photos",
            route: "/gallery",
            component: <Gallery />,
          },
          {
            name: "Videos",
            route: "/videos",
            // component: <ContactUs />,
            component: <VideoGallery />
          }
        ],
      },
    ],
  },
  // {
  //   name: "Gallery",
  //   route: "/gallery",
  //   component: <Gallery />,
  // },
  {
    name: "Contact Us",
    route: "/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
